import '@fontsource/poppins/300.css'; // Light
import '@fontsource/poppins/300-italic.css';
import '@fontsource/poppins/400.css'; // Regular
import '@fontsource/poppins/400-italic.css';
import '@fontsource/poppins/500.css'; // Medium
import '@fontsource/poppins/500-italic.css';
import '@fontsource/poppins/600.css'; // Semibold
import '@fontsource/poppins/600-italic.css';
import '@fontsource/dm-sans/500.css'; // Medium
import '@fontsource/dm-sans/500-italic.css';
import '@/styles/global.css';
import type { AppProps } from 'next/app';
import { GoogleTagManager, GoogleAnalytics } from '@next/third-parties/google';
import { initializeErrorTracking } from '@/utils';

// Init error tracking on the client
if (typeof window !== 'undefined') {
  initializeErrorTracking();
}

/**
 * Custom app component that applies to every page on the site, allowing us to
 * do things like pull in a global stylesheet.
 */
export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Component {...pageProps} />

      {process.env.NODE_ENV === 'production' && (
        <>
          {/* Ensure that changes are made to _document.tsx if this is modified */}
          <GoogleAnalytics gaId="G-24J789T2Q7" />
          <GoogleTagManager gtmId="GTM-KPB9SVXF" />
        </>
      )}
    </>
  );
}

import * as Sentry from '@sentry/browser';
import { ErrorInfo } from 'react';

/**
 * Initialize client-side error tracking.
 */
export function initializeErrorTracking() {
  // Don't use Sentry for local dev
  if (isSentryDisabled()) {
    return;
  }

  try {
    Sentry.init({
      // This corresponds to the `docs-site` project in Sentry
      dsn: 'https://8f275a369610465d63fbd08d0b8ac628@o465684.ingest.sentry.io/4506338667266048',
      environment: 'production',
      release: process.env.NEXT_PUBLIC_BUILD_VERSION,
    });
  } catch (err) {
    // Don't break the app if this fails for some reason
    console.error('Error initializing error reporting', err);
  }
}

/**
 * Basic utility for capturing and logging errors. Does not do any sort of analytics tracking, just reports errors.
 */
export function logError(error: unknown, reactErrorInfo?: ErrorInfo) {
  // Don't do error reporting to Sentry for local dev or if it's been disabled
  if (isSentryDisabled()) {
    console.error(error);
    return;
  }

  Sentry.captureException(error, {
    contexts: { react: reactErrorInfo as Record<string, unknown> },
  });
}

function isSentryDisabled() {
  if (process.env.NODE_ENV === 'development') {
    return true;
  }
  return false;
}
